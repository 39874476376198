function isValid(email) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
}
var allowSubmit = false;

function capcha_filled () {
    allowSubmit = true;
}

function capcha_expired () {
    allowSubmit = false;
}
function check_if_capcha_is_filled (e) {
    console.log('hello');
    if(allowSubmit) {
        return true;
    }
    else {
        alert('Nem vagyok robot ellenőrzésd kérlek töltsd ki!');
        //e.preventDefault();
        return false;
    }
}
if ($('.contact-form').length) {
    $(document).on('submit', '.contact-form', function(e) {
        if(check_if_capcha_is_filled() === false){
            e.preventDefault();
            return false;
        }
        var validated = 0;
        var recaptcha = $('.g-recaptcha');
        recaptcha.required = true;
        recaptcha.oninvalid = function(e) {
            // do something
            alert("Please complete the captcha");
            return false;
        }
        $('.form-input.mandatory').each(function() {
            if ($(this).val() != '') {
                validated++;
            } else {
                $(this).parent('.form-group').addClass('has-error');
            }
        });

        if (validated < $('.form-input.mandatory').length) {
            e.preventDefault();
            return false;
        }

        if (!isValid($('#l_contact_email').val())) {
            e.preventDefault();
            alert('Kérjük, valós e-mail címet adjon meg!');
            return false;
        }

        return true;
    });

    $(document).on('focus', '.contact-form .has-error', function() {
        $(this).removeClass('has-error');
    });
}